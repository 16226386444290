import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import { getUserAPI } from 'services/user';

import { CurrentUserEnum, LoginAuth } from 'types/AuthRequired';
import { State } from 'types/State';

export const AUTH_REDUCER_NAME = 'auth';

export const checkLoginStatus = createAsyncThunk(`${AUTH_REDUCER_NAME}/checkLoginStatus`, async () => {
  const response = await getUserAPI();

  return response.data;
});

export type AuthState = {
  userInfo: LoginAuth;
  state: State;
  error: null | SerializedError;
  loggedIn: boolean;
  previousState: null | string;
  userType: CurrentUserEnum;
  currentDB: null | string;
};

const initialState = {
  userInfo: {},
  state: State.NOT_STARTED,
  loggedIn: false,
  previousState: null,
  currentDB: null,
} as AuthState;

const slice = createSlice({
  name: AUTH_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkLoginStatus.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(checkLoginStatus.fulfilled, (draft, action) => {
      draft.state = State.SUCCESS;
      draft.error = null;
      draft.userInfo = action.payload;
      draft.loggedIn = action.payload.loginState.loggedIn;
      draft.userType = action.payload.loginState.userType as CurrentUserEnum;
      draft.currentDB = action.payload.currentDb;
    });
    builder.addCase(checkLoginStatus.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.loggedIn = false;
      draft.error = action.error;
      console.log('cannot get login state');
      console.log(action.error);
    });
  },
});

// export const {} = slice.actions;

export default slice.reducer;
