import { ReportFilters, ReportsParams } from 'services/customers/reports';

import { AlternativeSymbolNode } from 'types/CustomerReport';

import { EditPayloadNode, EditSymbolSelection, Parent, ReportContent, SymbolEntries } from './types';

/* Return map of symbols with their icons */
export const getSymbolsMap = (symbols: AlternativeSymbolNode[]) =>
  new Map<string, string>(symbols.map((symbol) => [symbol.displayName, symbol.icon ? symbol.icon : 'info']));

export const getParams = (reportFilters: ReportFilters, lastReportId?: number) => {
  const params: ReportsParams = {
    limit: 10,
  };
  if (reportFilters.startDate) {
    params.from = reportFilters.startDate;
  }

  if (reportFilters.endDate) {
    params.to = reportFilters.endDate;
  }

  if (reportFilters.searchKeys) {
    params.search = reportFilters.searchKeys;
  }

  if (reportFilters.symbolsId.length) {
    params.symbol_ids = reportFilters.symbolsId.toString();
  }

  if (lastReportId) {
    params.after = lastReportId;
  }
  if (reportFilters.sources) {
    params.sources = Array.from(reportFilters.sources || []);
  }
  return params;
};

/*
/*
 * Recursive function to get selected symbols
 * */
const getSymbolSelection = (report: ReportContent, parent: Parent, category: string): SymbolEntries[] => {
  const selectedSymbols: SymbolEntries[] = [];

  // children is there
  report.children?.forEach((children) => {
    if (children.dataClass === 'LEAF') {
      const symbol: EditPayloadNode = {
        displayName: children.name,
        reportName: children.name,
        id: children.id,
        type: children.type,
        parentId: report.id,
        category,
        parent,
      };

      if (children.type === 'text') {
        symbol.text = children.text;
      }

      selectedSymbols.push({ selectedLeafNode: symbol });
    } else {
      selectedSymbols.push(
        ...getSymbolSelection(
          children,
          {
            id: children.id,
            displayName: children.name,
            reportName: children.name,
            type: children.type,
            parentId: parent.id,
            parent,
          },
          category,
        ),
      );
    }
  });
  return selectedSymbols;
};

/*
 * This is to convert selected symbols JSON to old format because AddEditReportModal works on the old JSON format
 * */
export const revertSymbolSelectionToOldJSON = (reports: ReportContent[], symbolsMap: Map<string, string>) => {
  const convertedJSON: EditSymbolSelection[] = [];
  let selection: EditSymbolSelection;

  reports.forEach((report) => {
    selection = { entries: [], shownSymbol: symbolsMap.get(report.name) as string };

    // For the Free text
    if (report.dataClass === 'LEAF') {
      const freeText = {
        id: report.id,
        type: report.type,
        shownSymbol: 'NONE',
        text: report.text,
        entries: [],
      };
      convertedJSON.push(freeText);
    } else if (report.children) {
      const parent = {
        id: report.id,
        displayName: report.name,
        reportName: report.name,
        type: report.type,
      };
      selection.entries = getSymbolSelection(report, parent, report.name);
      convertedJSON.push(selection);
    }
  });

  return convertedJSON;
};
