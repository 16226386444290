/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode, ReactText } from 'react';
import ReactModal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import cn from 'classnames';
import { AxiosError } from 'axios';

import styles from 'common/Modal/Modal.scss';
import CloseIcon from 'common/icons/CloseIcon';
import HeaderActions, { ActionItem } from 'common/HeaderActions/HeaderActions';
import Info from 'common/Info';

import ErrorBoundary from 'sharedComponents/ErrorBoundary';

import HeaderBar from '../HeaderBar';
import { OverlaySpinner } from '../Spinner';

import ModalFooter from './ModalFooterNew';
import ModalBody from './ModalBody';
import ModalContent from './ModalContent';

export type ModalProps = Pick<Props, 'show' | 'onHide'>;

type Props = {
  show: boolean;
  onHide: () => void;
  children: ReactNode;
  title: ReactNode;
  size?: ModalSize;
  className?: string;
  headerClassName?: string;
  modalClassName?: string;
  headerActions?: ActionItem[];
  headerBar?: ReactNode;
  /**
   * @deprecated
   */
  subheader?: ReactNode;
  header?: ReactNode;
  showCloseIcon?: boolean;
  fullHeight?: boolean;
  scrollable?: boolean;
  loading?: boolean;
  loadingText?: ReactText;
  onAnimationEnd?: () => void;
  error?: AxiosError | string | null;
};

export enum ModalSize {
  AUTO = 'auto',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

const Modal = ({
  show,
  onHide,
  title,
  modalClassName,
  headerClassName,
  className,
  children,
  size,
  showCloseIcon,
  headerActions,
  headerBar,
  header,
  subheader,
  fullHeight,
  scrollable,
  loading,
  loadingText,
  error,
  ...rest
}: Props) => (
  <ReactModal
    open={show}
    onClose={() => onHide()}
    closeOnOverlayClick={false}
    classNames={{
      modal: cn(styles.modal, modalClassName, {
        [styles.xs]: size === ModalSize.XS,
        [styles.sm]: size === ModalSize.SM,
        [styles.md]: size === ModalSize.MD,
        [styles.lg]: size === ModalSize.LG,
        [styles.xl]: size === ModalSize.XL,
        [styles.auto]: size === ModalSize.AUTO,
        [styles.fullHeight]: fullHeight,
        [styles.scrollable]: scrollable,
      }),
      overlay: styles.overlay,
      closeButton: styles.closeIcon,
    }}
    closeIcon={<CloseIcon size={18} />}
    showCloseIcon={showCloseIcon}
    focusTrapped={false}
    {...rest}
  >
    <div className={cn(styles.content, className)}>
      <div className={cn(styles.header, headerClassName)}>{header || <h3 className={styles.heading}>{title}</h3>}</div>
      <ErrorBoundary>
        {headerActions && <HeaderActions items={headerActions} />}
        {headerBar && <HeaderBar>{headerBar}</HeaderBar>}
        {subheader}
        {error && <Info error>{error}</Info>}
        <div className={cn(styles.body, 'p-0', className)}>
          {loading && <OverlaySpinner text={loadingText} />}
          {children}
        </div>
      </ErrorBoundary>
    </div>
  </ReactModal>
);

Modal.Body = ModalBody;

Modal.Content = ModalContent;

Modal.Footer = ModalFooter;

export default Modal;
