import api from 'utils/api';

import {
  ATCMedication,
  DBSelection,
  PrescriptionPayload,
  PrescriptionStatus,
  PrintParams,
  RenewalRequest,
  Repudiation,
  ShortPrescription,
  Status,
  UnverifiedPrescription,
  VNRMedication,
  Amendment,
  PrescriptionMetaQuery,
  PrescriptionQuery,
  Audit,
  CurrentUser,
  SoteOrganisation,
  NewRenewalRequest,
  PrescriptionBase,
} from 'EResepti/types';
import { MEDICATION_STORAGE_KEY, ATC_STORAGE_KEY } from 'EResepti/constants';

export const getMedicationDataset = async () => {
  // check if session storage has the data
  const medData = sessionStorage.getItem(MEDICATION_STORAGE_KEY);
  if (medData) {
    return { data: JSON.parse(medData) };
  }
  // if not, fetch it, and store it to session storage
  const response = await api.get<VNRMedication[]>('dataset/medication');
  sessionStorage.setItem(MEDICATION_STORAGE_KEY, JSON.stringify(response.data));
  return response;
};

export const getAtcDataset = async (): Promise<{ data: ATCMedication[] }> => {
  // check if session storage has the data
  const atcData = sessionStorage.getItem(ATC_STORAGE_KEY);
  if (atcData) {
    const data = JSON.parse(atcData) as ATCMedication[];
    return { data };
  }
  // if not, fetch it, and store it to session storage
  const response = await api.get<ATCMedication[]>('dataset/atc');
  sessionStorage.setItem(ATC_STORAGE_KEY, JSON.stringify(response.data));
  return response;
};

export const postPrescription = (data: Partial<PrescriptionPayload>) =>
  api.post<PrescriptionPayload>('erec/recipes/', data);

export const getAuditLog = () => api.get<Audit[]>('erec/auditlog');

// will be user later
// export const getRestrictions = () => api.get('erec/restrictions');

// Auth related api's

// return type is unknown on purpose, because it is never read
export const postCredentials = (url: string) => api.post<unknown>(`login/card/authrequest/${url}`);
export const getStatus = (url: string) => api.get<Status>(`login/card/status/${url}`);
export const patchDatabase = (url: string, payload: DBSelection) =>
  api.patch<unknown>(`login/card/status/${url}`, payload);

// prescription related api's
export const getRecipesByStatus = (status: PrescriptionStatus[]) =>
  api.get<UnverifiedPrescription[]>('erec/recipes/own/', {
    params: {
      status,
    },
  });

export const getRecipeByOid = (oid: string) => api.get<ShortPrescription>(`erec/recipes/own/${oid}`);
export const getOwnPrescription = (setId: string) =>
  api.get<PrescriptionPayload>(`erec/recipes/own/${setId}/prescription`);
export const deleteOwnPrescription = (setId: string) =>
  api.delete<PrescriptionPayload>(`erec/recipes/own/${setId}/prescription`);

export const multiSignAPI = (setIds: string[]) =>
  api.post<PrescriptionPayload[]>('erec/recipes/own/multisign', { setID: setIds });
export const multiSignAPIStatus = (setIds: string[]) =>
  api.post<ShortPrescription[]>('erec/recipes/own/multisign/status', { setId: setIds });

export const getCustomerMetaRecipesCall = (data: PrescriptionMetaQuery) =>
  api.post<PrescriptionPayload[]>('erec/recipes/metaquery/', data);
export const getCustomerRecipesCall = (data: PrescriptionQuery) =>
  api.post<PrescriptionPayload[]>('erec/recipes/query/', data);

export const getSingleVnrMedication = (vnrId: number) => api.get<VNRMedication>(`dataset/medication/${vnrId}`);

// renewal requests
export const getAllMedicationRenewalRequests = () => api.get<RenewalRequest[]>('erec/recipes/renewal');
export const postNewMedicationRenewalRequest = (payload: NewRenewalRequest) =>
  api.post<RenewalRequest>('erec/recipes/renewal', payload);
export const postMedicationRenewalRequestAccept = (payload: RenewalRequest) =>
  api.post<RenewalRequest>('erec/recipes/renewal', payload);
export const postMedicationRenewalRejection = (payload: RenewalRequest) =>
  api.post<RenewalRequest>('erec/recipes/renewal/reject', payload);

export const postRepudiation = (payload: Repudiation) =>
  api.post<PrescriptionBase>('erec/recipes/repudiation', payload);

export const postAmendment = (payload: Amendment) => api.post<PrescriptionBase>('erec/recipes/amendment', payload);

// disclosureQuery. Similar to getCustomerRecipesCall, but doesn' take setIds as input
export const getDisclosures = (data: Omit<PrescriptionQuery, 'setID'>) =>
  api.post<PrescriptionBase[]>('erec/disclosure/query/', data);

/* Printing related apis */

export const getPatientInstruction = ({
  setId,
  queryReasonCode,
  patientSSN,
  consentCode,
}: PrintParams): Promise<void> =>
  api
    .post(
      'erec/recipes/print/patientinstruction',
      {
        setID: [setId],
        queryReasonCode: Number(queryReasonCode),
        patientSSN,
        consentCode: Number(consentCode),
      },
      { headers: { accept: 'application/pdf' }, responseType: 'blob' },
    )
    .then((response) => {
      const url = window.URL.createObjectURL(response.data);
      window.open(url, '_blank');
    });

// first posts, then gets the pdf and prints it
export const printSummaryBySetID = ({
  setID,
  queryReasonCode,
  patientSSN,
  consentCode,
  queryOnBehalf,
}: Omit<PrintParams, 'setId'> & { queryOnBehalf: number | string; setID: string[] }): Promise<void> =>
  api
    .post(
      'erec/recipes/print/summary',
      {
        setID,
        queryReasonCode, // check if number
        patientSSN,
        consentCode, // check if number
        queryOnBehalf, // also here
      },
      { headers: { accept: 'application/pdf' }, responseType: 'blob' },
    )
    .then((response) => {
      const url = window.URL.createObjectURL(response.data);
      window.open(url, '_blank');
    });

export const printDisclosure = ({
  queryReasonCode,
  patientSSN,
  consentCode,
}: Pick<PrintParams, 'queryReasonCode' | 'patientSSN' | 'consentCode'>): Promise<void> =>
  api
    .post(
      'erec/disclosure/print',
      {
        queryReasonCode,
        patientSSN,
        consentCode,
      },
      { headers: { accept: 'application/pdf' }, responseType: 'blob' },
    )
    .then((response) => {
      const url = window.URL.createObjectURL(response.data);
      window.open(url, '_blank');
    });

export const getCurrentUser = () => api.get<CurrentUser>('erec/currentuser');

export const getSoteOrganizations = (search?: string) =>
  api.get<SoteOrganisation[]>('datasets/soteorganizations', { params: { search } });
export const getSoteOrganizationsByOid = (oid: string) =>
  api.get<SoteOrganisation>(`datasets/soteorganizations/${oid}`);
