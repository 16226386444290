import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { CurrentUserEnum } from 'types/AuthRequired';

import { AuthState } from 'store/auth/slice';

const useSentry = (authState: AuthState) => {
  useEffect(() => {
    Sentry.configureScope((scope) => {
      if (authState.userType === CurrentUserEnum.PRIVILEGED_EARKISTO_USER_PRINCIPAL) {
        // We need to do this because Earkisto users are from outside the system and don't have a user id etc
        scope.setUser({
          id: 'AnonymousEArkistoUser',
          username: 'EArkisto User',
          email: 'none',
          currentDb: authState.currentDB,
          registeredDbs: [''],
        });
      } else {
        scope.setUser(
          authState.loggedIn
            ? {
                id: authState?.userInfo?.currentDomacareUser?.id?.toString(),
                username: `${authState?.userInfo.currentDomacareUser.lastName} ${authState.userInfo.currentDomacareUser.firstName}`,
                email: authState?.userInfo.currentDomacareUser.email,
                currentDb: authState?.userInfo.currentDb,
                registeredDbs: authState?.userInfo.registeredDbs,
              }
            : null,
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.loggedIn, authState.userType]);
};

export default useSentry;
