import { FC, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { captureException, Severity } from '@sentry/react';
import useLocale from 'hooks/useLocale';
import { importMessages, Locales, switchLocale } from 'i18n';
import Spinner from 'common/Spinner';
import SplashScreen from 'shared/SplashScreen';

const LanguageProvider: FC = ({ children }) => {
  const appLocale = useLocale();
  const [i18n, setI18n] = useState<{
    messages: Record<string, string>;
    locale: Locales;
  } | null>(null);

  useEffect(() => {
    (async () => {
      try {
        await switchLocale(appLocale);
        const msgs = await importMessages(appLocale);

        setI18n({ locale: appLocale, messages: msgs.default });
      } catch (e) {
        captureException(e, {
          level: Severity.Error,
        });
        // like this should not happened, but missing chunk or anything...
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appLocale]);

  if (!i18n)
    return (
      <SplashScreen>
        <Spinner />
      </SplashScreen>
    );

  const { locale, messages } = i18n;

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
