import { AlternativeRootNode, AlternativeSymbolNode, ReportPayload } from 'types/CustomerReport';
import { Customer } from 'types/Customer';

import api from 'utils/api';

import { ReportSource, ReportType } from 'sharedComponents/ReportDisplay/types';

export type ReportsParams = {
  limit: number;
  from?: string;
  to?: string;
  search?: string;
  after?: number;
  symbol_ids?: string;
  sources?: ReportSource[];
};

export type ReportFilters = {
  searchKeys: string;
  startDate: string | null;
  endDate: string | null;
  symbolsId: number[];
  include?: ReportSource[];
  sources?: Set<ReportSource>;
};

/* Basics */
export const getReports = (customerId: number, params: ReportsParams) =>
  api.get<ReportType[]>(`customers/${customerId}/v2/reports`, {
    params,
  });

// no return type
export const postReport = (customerId: number, payload: ReportPayload) =>
  api.post(`/customers/${customerId}/v2/reports/`, payload);

// no return type
export const editReport = (customerId: number, reportId: number, payload: ReportPayload) =>
  api.post(`/customers/${customerId}/v2/reports/${reportId}`, payload);

// not in use yet
export const postReportVersion = (customerId: number, reportId: number, payload: ReportPayload) =>
  api.post(`/customers/${customerId}/reports/${reportId}/versions`, payload);

// not in use yet
export const draftReport = (reportId: number, payload: ReportPayload) =>
  api.post(`/reports/${reportId}/versions/`, payload);

export const deleteReport = (customerId: number, reportID: number, deleteReason: string) =>
  api.delete(`/customers/${customerId}/v2/reports/${reportID}`, { params: { deleteReason } });

/* A little more special ones */

export const deleteReportReason = () => api.get(`/customers/v2/reports/delete_reasons`);

export const getAllowedCustomerList = () => api.get<Customer[]>(`/reports/customers`);

// no return type
export const postReportToMultipleCustomer = (customerIds: number[], payload: ReportPayload) =>
  api.post(`/customers/v2/reports/batch`, payload, {
    params: {
      customerIds,
    },
  });

/* Symbol related APIs */
export const getSymbols = (customerId?: number, rootId?: number) =>
  api.get<AlternativeRootNode>(`/symbolhierarchy/alternative`, { params: { customerId, rootId, fetchAll: false } });

// This is for the test because test won't work when one api is returning two different results
export const getSymbolChildren = (customerId: number, rootId?: number) =>
  api.get<AlternativeSymbolNode>(`/symbolhierarchy/alternative`, { params: { customerId, rootId, fetchAll: true } });
