/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import moment from 'moment';

import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';
import { DEFAULT_LOCALE, LOCAL_STORAGE_LOCALE_KEY } from 'App/constants';

export type Locales = 'en' | 'fi' | 'et';

export const appLocales = ['en', 'fi', 'et'];

const extractLanguageKeyFromLocalStorage = (): Locales => {
  if (process.env.NODE_ENV === 'test') return DEFAULT_LOCALE;
  try {
    return (localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) || DEFAULT_LOCALE) as Locales;
  } catch (error) {
    return DEFAULT_LOCALE;
  }
};

const fromLocalStorage = extractLanguageKeyFromLocalStorage();
export const storedLocale = appLocales.includes(fromLocalStorage) ? fromLocalStorage : DEFAULT_LOCALE;

moment.locale(storedLocale);

export function importMessages(locale: Locales): Promise<Record<string, any>> {
  switch (locale) {
    case 'fi':
      return import('./translations/fi');
    case 'et':
      return import('./translations/et');
    case 'en':
    default:
      return import('./translations/en');
  }
}

export async function switchLocale(locale: Locales) {
  localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale);
  await momentLocale(locale);
  moment.locale(locale);

  await polyfillPolyfillPluralRules(locale);
  await polyfillRelativeTimeFormat(locale);
}

export async function momentLocale(locale: Locales) {
  if (locale === 'en') return;
  await import(`moment/locale/${locale}`);
}

export async function polyfillIntlLocale() {
  // This platform already supports Intl.Locale
  if (shouldPolyfillIntlLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }
}

export async function polyfillRelativeTimeFormat(locale: Locales) {
  if (!shouldPolyfillRelativeTimeFormat()) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-relativetimeformat/polyfill');

  switch (locale) {
    case 'fi':
      await import('@formatjs/intl-relativetimeformat/locale-data/fi');
      break;
    case 'et':
      await import('@formatjs/intl-relativetimeformat/locale-data/et');
      break;
    default:
      await import('@formatjs/intl-relativetimeformat/locale-data/en');
      break;
  }
}

export async function polyfillPolyfillPluralRules(locale: Locales) {
  if (!shouldPolyfillPluralRules()) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-pluralrules/polyfill');

  switch (locale) {
    default:
      await import('@formatjs/intl-pluralrules/locale-data/en');
      break;
    case 'fi':
      await import('@formatjs/intl-pluralrules/locale-data/fi');
      break;
    case 'et':
      await import('@formatjs/intl-pluralrules/locale-data/et');
      break;
  }
}
