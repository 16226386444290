import axios, { AxiosError, AxiosResponse } from 'axios';

export const extractErrorMsg = (err: AxiosError) => err?.response?.data.messages[0]?.message;

export function isAxiosError(error: Error | AxiosError): error is AxiosError {
  return axios.isAxiosError(error);
}

export const extractMessage = (response: AxiosResponse) => {
  let message = 'UNKNOWN ERROR';
  if (response && response.data && response.data.messages) {
    message = response.data.messages[0].message;
  }
  return message;
};
