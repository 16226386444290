export enum AnswerStrings {
  STRING_ANSWER = 'RaiStringAnswerValue',
  RAI_TABLE_ANSWER_VALUE = 'RaiTableAnswerValue',
}

export type QuestionType = 'RaiChoiceQuestion';

export enum Languages {
  FIN = 'FIN',
  ENG = 'ENG',
  SVE = 'SVE',
  EST = 'EST',
}

export enum QuestionTypes {
  TABLE = 'TABLE',
  DATE = 'DATE',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  TEXTFIELD = 'TEXTFIELD',
  NUMBERFIELD = 'NUMBERFIELD',
  SPINNER = 'SPINNER',
  STATIC_TEXT = 'STATIC_TEXT',
  DELETE_BUTTON = 'DELETE_BUTTON',
  DECIMAL = 'DECIMAL',
  DATASET = 'DATASET',
}

export enum TableTypes {
  DIAGNOSES = 'DIAGNOSES',
  OWN_DIAGNOSES = 'OWN_DIAGNOSES',
  DIAGNOSES_PSYCHIATRIC = 'DIAGNOSES_PSYCHIATRIC',
  DIAGNOSES_SOMATIC = 'DIAGNOSES_SOMATIC',
  OWN_GOAL_EVALUATION = 'OWN_GOAL_EVALUATION',
  OWN_GOAL_FINAL = 'OWN_GOAL_FINAL',
  OWN_GOAL_INITIAL = 'OWN_GOAL_INITIAL',
  MEDICATION = 'MEDICATION',
  PARTICIPANTS = 'PARTICIPANTS',
  TODO = 'TODO',
}

export enum RowAnswersStatus {
  UNCHECKED = 'UNCHECKED',
  CHECKED = 'CHECKED',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  TRUE = 'true',
  FALSE = 'false',
}

export type Language = 'FIN' | 'ENG' | 'EST';

export enum Expand {
  INFO = 'info',
  SUPPLEMENTARY_INFO = 'supplementaryInfo',
}

export enum Instruments {
  LTC = 'LTC',
  HC = 'HC',
  ID = 'ID',
  LTCF = 'LTCF',
  LTCF_EST = 'LTCF_EST',
  CHA = 'CHA',
  CMH = 'CMH',
  CHYMH_DD = 'CHYMH_DD',
}

export enum InstrumentsDetail {
  LTC = 'LTC (Laitoshoito)',
  HC = 'HC (Kotihoito)',
  ID = 'ID (Kehitysvammatyö)',
  LTCF_EST = 'LTCF_EST',
  CHA = 'CHA (Palvelutarpeen arviointi)',
  CMH = 'CMH',
  CHYMH_DD = 'CHYMH_DD',
  LTCF = 'LTCF',
}

export type Version = {
  versionId: number;
  startDate: string;
  name: string;
  availableLanguages: string[];
};
export type InstrumentList = {
  instrumentEnum: string;
  versions: Version[];
};
export type InstrumentsList = InstrumentList[];

export type RowAnswer = {
  rowId?: number;
  columns: AnswerColumn[];
};

export type AnswerColumn = {
  questionId: string;
  label?: string;
  iCode?: string;
  value: string | null;
  rowId?: string;
};

export type QuestionAnswer = {
  type?: string;
  rows?: RowAnswer[];
  validationError?: string;
  fromPrevious?: boolean;
  hidden: boolean;
  unmodifiable?: boolean;
  questionId: string;
  skipped?: boolean;
  value?: string;
};

export type SubcategoryAnswer = { label: string; answers: Record<string, QuestionAnswer>; hidden: boolean };

export type CategoryAnswer = { label: string; subcategories: Record<string, SubcategoryAnswer>; hidden: boolean };

export type Assessment<T extends Instruments> = {
  id: number;
  customerId: number;
  type: T;
  created: string;
  archiveTimestamp: string;
  locked: boolean;
  deleted: boolean;
  definitionVersionId: number;
  archived: boolean;
  lastModifierUser: string;
};

export type AnswerValue = {
  answerType: string;
  relatedQuestionId: string;
  value?: string | null;
  type?: string;
  rowAnswers?: RowAnswer[];
};
export type AnswerPayload = {
  questionId: string;
  value: string | null;
};

export type TableAnswerPayload = {
  questionId: string;
  rows: RowAnswer[];
};

export type Answers = Record<string, CategoryAnswer>;

export type AnswerResponse = { answers: Answers; answerProgression: AnswerProgression };

export type Progression = {
  errorAnswerCount: number;
  totalQuestionCount: number;
  validAnswerCount: number;
  warningAnswerCount: number;
};

export type AnswerProgression = {
  categoryProgression: Record<string, Progression>;
  fullProgression: Progression;
};
export type Choices = { increment?: number; max?: number; min?: number; choices: Choice[] };
export type Choice = { value: string; label: string };
export type AllChoices = Record<string, Choices>;

export type ColumnDefinition = {
  answerSetId: number;
  id: string;
  label: string;
  type: string;
  iCode: string;
};
export type Category = { label: string; subcategories: Subcategory[] };
export type Question = {
  columnDefinitions?: ColumnDefinition[];
  tableType?: TableTypes;
  answerSetId?: number;
  id: string;
  label: string;
  explanation?: string;
  optional: boolean;
  type: QuestionTypes;
};
export type Subcategory = {
  label: string;
  displayTitle?: string;
  questions: Question[];
};

export type AffectingQuestions = {
  categories: Category[];
  allChoices: AllChoices;
  instrumentEnum: Instruments;
  language: Language;
};
export type InstrumentQuestions = {
  allChoices: AllChoices;
  categories: Category[];
  versionId: number;
  instrumentEnum: Instruments;
  name: string;
};
export type ValueDescription = { value: string; description: string; type: string };
export type ScoreTypeInfo = {
  value: string;
  name: string;
  valueDescriptions: ValueDescription[];
  affectingQuestions: AffectingQuestions;
};
export type ScoreType = {
  description: string;
  id: string;
  info: ScoreTypeInfo;
  name: string;
  value: string;
  valueDescription: string;
};

export type PossibleValue = { value: string; description: string; type: string; label?: string };
export type CapsAndRapsType = {
  description: string;
  id: string;
  info: {
    possibleValues: PossibleValue[];
    symbol: string;
    description?: string;
    label?: string;
  };
  name: string;
  value: string;
  valueDescription: string;
};

export type Note = {
  id: number;
  instrument: string;
  questionId: string;
  note: string;
  created: string;
  assessmentId: string;
};

export type QuestionIdsMap = Record<
  string,
  {
    categoryIndex: number;
    categoryLabel: string;
    subCategoryIndex: number;
    questionIndex: number;
    questionType: string;
    subCategoryLabel: string;
    subcategoryDisplayTitle: string;
  }
>;

export enum QuestionsPriority {
  LAST_QUESTION = 'LAST_QUESTION',
  FIRST_QUESTION = 'FIRST_QUESTION',
  QUESTION = 'QUESTION',
}

export type RaiMeasurementCalculationResultDTO = {
  value: string;
  description: string;
  assessmentId: number;
  iCode: string;
};

export type InstrumentDefinitionDTO = {
  instrumentEnum: string;
  versionId: number;
  name: string;
  categories: Category[];
  allChoices: AllChoices;
};

export type InstrumentScale = {
  name: string;
  finnishName: string;
  acronym: string;
  rangeMin: number;
  rangeMax: number;
  description: string;
  instrumentDefinitionDTO: InstrumentDefinitionDTO;
  raiMeasurementCalculationResultDTO: RaiMeasurementCalculationResultDTO;
};

export type AssessmentMeasurements = {
  instrumentScales: InstrumentScale[];
  clinicalAssessmentProtocols: InstrumentScale[];
};

export type PostRaiNotePayload = {
  note: string;
  questionId: string;
  assessmentId: string;
};

export type GetNotesParams = {
  questionId: string;
};
