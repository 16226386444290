import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useBoolean } from 'usehooks-ts';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import Modal, { ModalProps, ModalSize } from 'common/Modal';
import Button from 'common/Button';
import Checkbox from 'common/Checkbox';
import HeaderActions from 'common/HeaderActions/HeaderActions';
import HeaderLabel from 'common/HeaderLabel';

import { getSymbols } from 'services/customers/reports';
import {
  getReportTransferStatus,
  postEarkistoLogin,
  logoutEArkisto,
  postReportTransfer,
  getEarkistoLoginStatus,
} from 'services/Kanta';

import useApi from 'hooks/useApi';

import {
  EArkistoLoginStatus,
  EarkistoTransferOrder,
  EarkistoTransferOrderResponse,
  EArkistoTransferOrderStatus,
  ReportTransferOrder,
} from 'types/Kanta';
import { CustomerReport } from 'types/CustomerReport';

import { formatDateToDisplayDateTimeFormat } from 'utils/dateUtil';

import commonMessages from 'translations/common';

import SymbolIcon from 'sharedComponents/SymbolIcon';
import EArkistoSelector from 'nonRouteFeatures/EArkisto/selectors';
import { getSymbolsMap } from 'sharedComponents/ReportDisplay/utils';
import ReportDisplayEventEmitter from 'sharedComponents/ReportDisplay/ReportDisplayEventEmitter';

import EArkistoMessages from './messages';
import styles from './styles.scss';

type Props = ModalProps;

// Used for the header actions
enum ReportType {
  TRANSFERRABLE,
  TRANSFERRED,
}

/*
 * NOTE: every transfer must be preceded by a login to atostek eArkisto API
 * Whether the transfer succeeds or not, it must be accompanied by a logout to avoid problems with our own user principals
 * This is why transfers and closing the modal etc always use logout
 * There is a separate Sentry config for eArkisto principals so we can track these problems separately from our own
 * */
const EArkistoModal: FC<Props> = ({ show, onHide }) => {
  const { transferrableReports, transferredReports } = useSelector(EArkistoSelector);
  const { data: symbolsTree, fetch: fetchSymbolsTree } = useApi(getSymbols);
  const [selectedDocs, setSelectedDocs] = useState(new Map());
  const { setTrue: setLoading, setFalse: setNotLoading, value: loading } = useBoolean(false);
  const [error, setError] = useState('');
  const [selectedReportType, setSelectedReportType] = useState<ReportType>(ReportType.TRANSFERRABLE);
  const { formatMessage } = useIntl();
  const ref = useRef<EarkistoTransferOrder | null>(null);
  const pollingInterval = useRef<NodeJS.Timeout | null>(null);
  const [pollingStatus, setPollingStatus] = useState<EArkistoLoginStatus | EArkistoTransferOrderStatus | null>(null);

  useEffect(() => {
    fetchSymbolsTree();
  }, [fetchSymbolsTree]);

  const handleCheckboxChange = (checked: boolean, docId: number, doc: CustomerReport) => {
    const newSelectedDocs = new Map(selectedDocs);
    if (checked) {
      newSelectedDocs.set(docId, doc);
    } else {
      newSelectedDocs.delete(docId);
    }
    setSelectedDocs(newSelectedDocs);
  };

  const pollLoginStatus = () =>
    new Promise<void>((resolve, reject) => {
      pollingInterval.current = setInterval(async () => {
        const response = await getEarkistoLoginStatus();
        if (response.data === EArkistoLoginStatus.active) {
          clearInterval(pollingInterval.current as NodeJS.Timeout);
          resolve();
        } else if (response.data === EArkistoLoginStatus.locked || response.data === EArkistoLoginStatus.expired) {
          clearInterval(pollingInterval.current as NodeJS.Timeout);
          reject(new Error('login failed'));
        }
      }, 2000);
    });

  // TODO: This will be a BE job in the future
  const pollTransferStatus = (transferOrder: EarkistoTransferOrderResponse) =>
    new Promise<void>((resolve, reject) => {
      pollingInterval.current = setInterval(async () => {
        const response = await getReportTransferStatus(transferOrder.id);
        if (response.data.state === EArkistoTransferOrderStatus.FAILED) {
          clearInterval(pollingInterval.current as NodeJS.Timeout);
          reject(new Error(formatMessage(EArkistoMessages.transferFailed)));
        } else if (response.data.state === EArkistoTransferOrderStatus.SUCCEEDED) {
          clearInterval(pollingInterval.current as NodeJS.Timeout);
          resolve();
        } else if (response.data.state !== EArkistoTransferOrderStatus.IN_PROGRESS) {
          clearInterval(pollingInterval.current as NodeJS.Timeout);
          reject(new Error('Polling was cancelled'));
        }
      }, 2000);
    });

  const submitSelectedDocs = async () => {
    setError('');
    const reports: ReportTransferOrder[] = Array.from(selectedDocs.values()).map((doc) => ({
      reportId: doc.id,
      versionNumber: doc.version,
    }));

    const transferOrder: EarkistoTransferOrder = { reports };

    try {
      setLoading();
      const { data } = await postEarkistoLogin();
      window.open(data.uri, '_blank');

      setPollingStatus(EArkistoLoginStatus.in_login);
      await pollLoginStatus();
      const { data: formData } = await postReportTransfer(transferOrder);
      setPollingStatus(EArkistoTransferOrderStatus.IN_PROGRESS);
      await pollTransferStatus(formData);
    } catch (e) {
      setError(e.message);
    } finally {
      ref.current = null;
      setNotLoading();
      setPollingStatus(null);
      await logoutEArkisto();
      ReportDisplayEventEmitter.emit('refresh');
    }
  };

  const handleHide = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
      logoutEArkisto();
    }
    onHide();
  };

  const headerActionItems = [
    {
      label: formatMessage(EArkistoMessages.transferrableReports),
      onClick: () => {
        setSelectedReportType(ReportType.TRANSFERRABLE);
      },
      active: selectedReportType === ReportType.TRANSFERRABLE,
    },
    {
      label: formatMessage(EArkistoMessages.transferredReports),
      onClick: () => {
        setSelectedReportType(ReportType.TRANSFERRED);
      },
      active: selectedReportType === ReportType.TRANSFERRED,
    },
  ];

  const orderReportsByCustomer = () => {
    const reportsByCustomer = new Map();
    (selectedReportType === ReportType.TRANSFERRABLE ? transferrableReports : transferredReports)?.forEach((report) => {
      const customerId = report.customer.id;
      if (!reportsByCustomer.has(customerId)) {
        reportsByCustomer.set(customerId, []);
      }
      reportsByCustomer.get(customerId).push(report);
    });

    return Array.from(reportsByCustomer.entries());
  };

  const symbolsMap = symbolsTree?.children ? getSymbolsMap(symbolsTree?.children) : new Map();

  const getLoadingText = () => {
    if (pollingStatus === EArkistoLoginStatus.in_login) {
      return formatMessage(EArkistoMessages.inLogin);
    }
    if (pollingStatus === EArkistoTransferOrderStatus.IN_PROGRESS) {
      return formatMessage(EArkistoMessages.transferring);
    }
    return '';
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      size={ModalSize.LG}
      title={formatMessage(commonMessages.eArkisto)}
      scrollable
      loading={loading}
      error={error}
      loadingText={getLoadingText()}
    >
      <HeaderActions items={headerActionItems} />
      <Modal.Body>
        {orderReportsByCustomer().map(([customerId, reports]) => (
          <div key={customerId}>
            <HeaderLabel label={`${reports[0].customer.firstName} ${reports[0].customer.lastName}`} />
            {reports.map((report: CustomerReport, index: number) => (
              <div
                className={cn({
                  'border-bottom': index !== reports.length - 1,
                })}
                key={`eArchive_customer_report_${report.id}`}
              >
                <div>
                  <span className="float-right font-weight-bolder">
                    {formatDateToDisplayDateTimeFormat(report.eventTime)}
                  </span>
                </div>
                <div key={report.id} className={cn('d-flex flex-row pt-3 pb-3')}>
                  {selectedReportType === ReportType.TRANSFERRABLE && (
                    <div className="d-flex align-items-center mr-3">
                      <Checkbox
                        checked={selectedDocs.has(report.id)}
                        onChange={(checked) => handleCheckboxChange(checked, report.id, report)}
                      />
                    </div>
                  )}
                  <div className={styles.symbolGrid}>
                    {report.content.map((symbol) => (
                      <span key={`symbol_content${symbol.id}`}>
                        <SymbolIcon key={symbol.id} icon={symbolsMap.get(symbol.name)} />
                        <span className="ml-1">{symbol.name}</span>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" onClick={handleHide}>
          {formatMessage(commonMessages.close)}
        </Button>
        <Button
          success
          disabled={selectedDocs.size === 0 || selectedReportType === ReportType.TRANSFERRED}
          type="button"
          onClick={submitSelectedDocs}
        >
          {formatMessage(commonMessages.send)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EArkistoModal;
