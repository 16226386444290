import { DateRangeType } from 'common/form/pickers/DateRangePicker';

import { Unit } from 'types/Unit';
import { Customer } from 'types/Customer';

import { Employee } from 'Booking/types/Employee';

export type Deviation = {
  workshiftId: number;
  plannedStartTime: string;
  plannedEndTime: string;
  plannedDuration: number;
  realizedStartTime: string;
  realizedEndTime: string;
  realizedDuration: number;
  deviation: number;
  reason: string;
};

export type ShortEmployee = Pick<Employee, 'firstName' | 'lastName' | 'id'> & { externalId: string };

export type ShortUnit = Pick<Unit, 'id' | 'name' | 'profitCenter'> & { costCenter: string };
export type SalaryUnit = Pick<ShortUnit, 'id' | 'name' | 'costCenter'>;

export type ShortShift = {
  id: number;
  plannedStartTime: string;
  plannedEndTime: string;
  plannedDuration: number;
  realizedStartTime: string;
  realizedEndTime: string;
  realizedDuration: number;
  additionalInformation?: string;
};

export type ShortService = { id: number; name: string };

export type ContributingTask = {
  realizedStartTime: string;
  realizedEndTime: string;
  service: ShortService;
  customer: Pick<Customer, 'id' | 'firstName' | 'lastName'>;
  taskType: string;
  taskId: number;
  count: number;
  additionalInformation?: string;
} & Pick<FlatDetail, 'total' | 'cpu' | 'pcs' | 'note' | 'euros'>;

export enum Source {
  WORKSHIFTS = 'WORKSHIFTS',
  TASKS = 'TASKS',
  PERIODS = 'PERIODS',
  ABSENCES = 'ABSENCES',
}

export type SalaryDetail = {
  salaryTypeCode: string;
  pcs: number;
  euros: number;
  accepted: boolean;
  payGrade: string;
  cpu: number;
  definition?: string;
  salaryCostCenter?: string;
  additionalInfo: {
    contributingShifts: ShortShift[];
    contributingTasks: ContributingTask[];
    periodSummaries: OverTimePeriods[];
  };
  note: string; // not yet there... BUT WILL BE
  source: Source;
  errors?: SalaryError[];
};

export type Salary = {
  employee: ShortEmployee;
  unit: ShortUnit;
  total: number;
  plannedMinutes: number;
  realizedMinutes: number;
  deviation: number;
  details: SalaryDetail[];
  deviations?: Deviation[];
  lockedBy?: number;
  periods?: OverTimePeriods[];
  errors?: SalaryError[];
};

export type SalaryType = { unit: string; name: string; code: string };

export type FormProps = FilterProps & {
  employees: number[];
  startDate: string | null;
  endDate: string | null;
  description?: string;
  fileName?: string;
  afterSuccess?: () => void;
  afterFailed?: () => void;
};

export type FilterProps = {
  dateRange: DateRangeType;
  status: string;
  timetabs: number[];
  units: number[];
  agreements: number[];
};

export type ApprovalProps = Pick<FormProps, 'startDate' | 'endDate' | 'employees' | 'description' | 'fileName'>;

export enum Status {
  UNAPPROVED = 'UNAPPROVED',
  APPROVED = 'APPROVED',
  EXPORTED = 'EXPORTED',
}

export type EmployeeAgreement = {
  name: string;
  id: number;
};

export type FlatDetail = SalaryDetail & {
  unit: ShortUnit;
  employee: ShortEmployee;
  total: number;
  realizedMinutes: number;
  eventDate?: string;
  periods?: OverTimePeriods[];
  salaryTypeName: string; // we create this in FE for searching
};

export enum Periods {
  PLANNING_PERIOD1 = 'planningPeriod1',
  PLANNING_PERIOD2 = 'planningPeriod2',
  EQUALIZATION_PERIOD = 'equalizationPeriod',
}

export type OverTimeDetail = {
  startDate: string;
  endDate: string;
  realizedMinutes: number;
  overtimeLimitMinutes: number;
  fiftyPercentMinutes: number;
  hundredPercentMinutes: number;
  totalOvertimeMinutes: number;
  type?: string;
  additionalInformation?: string;
};

export type OverTimePeriods = {
  planningPeriod1: OverTimeDetail;
  planningPeriod2: OverTimeDetail;
  equalizationPeriod: OverTimeDetail;
};

export type OverTime = Required<Omit<FlatDetail, 'salaryTypeName'>>;

export type OverTimeTableData = OverTimeDetail[];

export type SalaryError = {
  sourceStartTime: Date;
  errorCode: SalaryErrorCodes | SalaryWarningCodes;
  errorSource: string;
  errorSourceId: number;
  severity: SeverityType;
};

export enum SeverityType {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum ErrorSource {
  SHIFT = 'SHIFT',
}
export type PayrollPrivilegedUnits = Array<Pick<ShortUnit, 'id' | 'name'>>;

export enum SalaryErrorCodes {
  MISSING_TES_DATA = 'MISSING_TES_DATA',
  MISSING_SHIFT_TEMPLATE = 'MISSING_SHIFT_TEMPLATE',
  MISSING_ABSENCE_TYPE = 'MISSING_ABSENCE_TYPE',
  MISSING_STAND_BY_TYPE = 'MISSING_STAND_BY_TYPE',
}

export enum SalaryWarningCodes {
  MISSING_CUSTOM_TYPE_CONFIG = 'MISSING_CUSTOM_TYPE_CONFIG',
}

export type ShowWorkShiftFunc = (
  editWorkShiftId: number,
  visibleTimeStart: number,
  employeeId: number,
  cb?: () => void,
) => void;

export type payrollExportParameters = {
  employees: number[];
  description: string;
  fileName: string;
  endDate: string;
  startDate: string;
};

export type payrollExcludeCorrectionParameters = {
  userId: number;
  excludedLines: [
    {
      eventDate: string;
      salaryType: string;
      costCenter: string;
      payGrade: string;
    },
  ];
};

export type PayrollCorrectionPayload = {
  payrollExportParameters: payrollExportParameters;
  payrollExcludeCorrectionParameters?: payrollExcludeCorrectionParameters[];
};
